export const state = {
  items: [],
  loading: false,
  totalItems: 0,
  allergens: [],
  selectedIngredient: null,
  // TODO: adjust to normal pagination items
  allItems: [],
  groupItems: [],
  isError: false,
  errorMessage: null
};
