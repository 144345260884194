import { API, ApiKey } from '../../../config';
import axios from 'axios';
import qs from 'qs';

const headerAPI = { ApiKey: `${ApiKey}`};

export const actions = {
  async AuthRequest({ dispatch, commit }, { email, password }) {
    try {
      commit('AuthRequest');
      const { data } = await axios.post(`${API}/api/users/sessions`, qs.stringify({ email, password }), { headers: headerAPI });
      if(data.role === 'ADMIN' || data.role === 'AUTHOR') {
        localStorage.setItem('user-token', data.token);
        axios.defaults.headers.common['token'] = data.token;
        commit('AuthRequestSuccess', { token: data.token });
        dispatch('LoadCurrentUser');
      } else {
        commit('AuthRequestFailure', { message: 'This user is not Admin.' });
      }
    } catch (err) {
      localStorage.removeItem('user-token');
      commit('AuthRequestFailure', { message: 'Access denied.' });
    }
  },
  async AuthLogOut({ commit }) {
    commit('AuthLogOut');
    localStorage.removeItem('user-token');
    delete axios.defaults.headers.common['token'];
  },
  // TODO: Move this action to the appropiate store
  ToggleMenu({ commit }) {
    commit('ToggleMenu');
  },
  async LoadCurrentUser({ commit }) {
    try {
      axios.defaults.headers.common['token'] = localStorage.getItem('user-token');
      const { data } = await axios.get(`${API}/api/users/current`, { headers: headerAPI });
      commit('LoadCurrentUserSuccess', { user: data });
    } catch (err) {
      localStorage.removeItem('user-token');
      commit('LoadCurrentUserFailure');
    }
  },
};
