export const mutations = {
  LoadingIngredients(state) {
    state.loading = true;
    state.isError = false;
  },
  LoadIngredientsSuccess(state, { items, total }) { 
    state.items = [...items];
    state.totalItems = total;
    state.loading = false;
    state.isError = false;
  },
  LoadIngredientsFailure(state, { message }) {
    state.loading = false;
    state.isError = true;
    state.errorMessage = message;
  },
  LoadAllGroupIngredientsSuccess(state, { items }) { 
    state.groupItems = [...items];
    state.loading = false;
    state.isError = false;
  },
  LoadAllGroupIngredientsFailure(state, { message }) {
    state.loading = false;
    state.isError = true;
    state.errorMessage = message;
  },
  LoadAllIngredientsSuccess(state, { items }) { 
    state.allItems = [...items];
    state.loading = false;
    state.isError = false;
  },
  LoadAllIngredientsFailure(state, { message }) {
    state.loading = false;
    state.isError = true;
    state.errorMessage = message;
  },
  LoadingAllergens(state) {
    state.loading = true;
    state.isError = false;
  },
  LoadAllergensSuccess(state, { items }) { 
    state.allergens = [...items];
    state.loading = false;
    state.isError = false;
  },
  LoadAllergensFailure(state, { message }) {
    state.loading = false;
    state.isError = true;
    state.errorMessage = message;
  },
  CreateNewIngredient(state) {
    state.loading = true;
    state.isError = false;
  },
  CreateNewIngredientSuccess(state, { ingredient }) { 
    state.items = [...state.items, ingredient];
    state.loading = false;
    state.isError = false;
  },
  CreateNewIngredientFailure(state, { message }) {
    state.loading = false;
    state.isError = true;
    state.errorMessage = message;
  },
  DeleteIngredientSuccess(state, { id }) {
    state.items = [...state.items.filter((item) => item.id !== id)];
    state.isError = false;
  },
  LoadIngredientSuccess(state, { ingredient }) {
    state.selectedIngredient = {
      ...ingredient,
      includeInShoppingList: !!ingredient.includeInShoppingList
    };
    state.isError = false;
  },
  UpdateIngredientSuccess(state, { ingredient }) {
    const ingredientsList = [...state.items];
    const index = ingredientsList.findIndex((obj) => obj.id == ingredient.id);
    ingredientsList[index] = ingredient;

    state.items = [...ingredientsList];
    state.isError = false;
  },
  UpdateIngredientFailure(state, { message }) {
    state.isError = true;
    state.errorMessage = message;
  },
  DeleteIngredientCompoundSuccess(state) {
    state.isError = false;
  },
  DeleteIngredientCompoundFailure(state, { message }) {
    state.isError = true;
    state.errorMessage = message;
  },
};
