import Vue from 'vue';
import Vuex from 'vuex'
import { ingredientsModule } from './modules/ingredients';
import { recipesModule } from './modules/recipes';
import { menusModule } from './modules/menus';
import { usersModule } from './modules/users';
import { userModule } from './modules/user';

Vue.use(Vuex)

export default new Vuex.Store({
  modules: { 
    ingredients: ingredientsModule,
    recipes: recipesModule,
    menus: menusModule,
    users: usersModule,
    user: userModule
  }
});
