import store from '../store/index';
import { TABLE_NUM_ITEMS } from '../constants';

const editIngredientsGuard = (to, from, next) => {
  if (!store.getters['ingredients/simpleIngredients'].length) {
    store.dispatch('ingredients/LoadIngredients', { offset: 0, limit: TABLE_NUM_ITEMS }).then(() => next());
  }
  if (!store.getters['ingredients/allergens'].length) {
    store.dispatch('ingredients/LoadAllergens').then(() => next());
  }
  store.dispatch('ingredients/LoadAllIngredients').then(() => next());
  next();
}

export default editIngredientsGuard;
