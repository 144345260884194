const recipeIngredientMaptoDB = {
  id: 'id',
  qty: 'qty',
  units: 'units_of_measure',
  variant: 'variant',
  alternativeId: 'alternative_id',
  order: 'order',
  starred: 'starred',
  impliesRecipeModification: 'implies_recipe_modification',
  type: 'type',
  recipeId: 'recipe_id',
  ingredientId: 'ingredient_id',
  group: 'group'
};

const recipeIngredientMaptoUI = {
  id: 'id',
  qty: 'qty',
  units_of_measure: 'units',
  variant: 'variant',
  alternative_id: 'alternativeId',
  order: 'order',
  starred: 'starred',
  implies_recipe_modification: 'impliesRecipeModification',
  type: 'type',
  recipe_id: 'recipeId',
  ingredient_id: 'ingredientId',
  group: 'group'
};

const recipeIngredientMapper = (step, direction) => {
  let recipeIngredientMapped = {};
  Object.keys(step).forEach((key) => {
    let mapper;
    if (direction === 'toDB') {
      mapper = recipeIngredientMaptoDB;
      const newKey = mapper[key];

      if (newKey && typeof step[key] !== 'undefined') {
        recipeIngredientMapped[newKey] = step[key];
      }
    } else {
      mapper = recipeIngredientMaptoUI;
      const newKey = mapper[key] || key;
      recipeIngredientMapped[newKey] = step[key];
    }
  });

  return recipeIngredientMapped;
};

export default recipeIngredientMapper;
