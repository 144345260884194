export const mutations = {
  LoadingMenus(state) {
    state.loading = true;
  },
  LoadMenusSuccess(state, { items }) {
    state.items = [...items];
    state.loading = false;
    state.isError = false;
  },
  LoadMenusFailure(state, { message }) {
    state.loading = false;
    state.isError = true;
    state.errorMessage = message;
  },
  LoadMenuTotalItemsSuccess(state, { totalItems }) {
    state.totalItems = totalItems;
    state.loading = false;
    state.isError = false;
  },
  LoadMenusTotalItemsFailure(state, { message }) {
    state.loading = false;
    state.isError = true;
    state.errorMessage = message;
  },
  CreateNewMenuSuccess(state) {
    state.isError = false;
  },
  CreateNewMenuFailure(state, { message }) {
    state.isError = true;
    state.errorMessage = message;
  },
  DeleteMenuSuccess(state, { id }) {
    state.items = [...state.items.filter((item) => item.week_id !== id)];
    state.isError = false;
  },
  DeleteMenuFailure(state, { message }) {
    state.isError = true;
    state.errorMessage = message;
  },
  UpdateMenuSuccess(state) {
    state.isError = false;
  },
  UpdateMenuFailure(state, { message }) {
    state.isError = true;
    state.errorMessage = message;
  },
};
