export const mutations = {
  LoadingUsers(state) {
    state.loading = true;
  },
  LoadUsersSuccess(state, { items, total }) { 
    state.items = [...items];
    state.totalItems = total;
    state.loading = false;
    state.isError = false;
  },
  LoadAllUsersSuccess(state, { items }) { 
    state.allItems = [...items];
    state.isError = false;
  },
  LoadUsersFailure(state, { message }) {
    state.loading = false;
    state.isError = true;
    state.errorMessage = message;
  },
  LoadUserSuccess(state, { item }) { 
    state.item = item ;
    state.loading = false;
    state.isError = false;
  },
  LoadUserFailure(state, { message }) {
    state.loading = false;
    state.isError = true;
    state.errorMessage = message;
  },
  CreateNewUser(state) {
    state.loading = true;
    state.isError = false;
  },
  CreateNewUserSuccess(state, { user }) { 
    state.items = [...state.items, user];
    state.loading = false;
    state.isError = false;
  },
  CreateNewUserFailure(state, { message }) {
    state.loading = false;
    state.isError = true;
    state.errorMessage = message;
  },
  DeleteUserSuccess(state, { id }) {
    state.items = [...state.items.filter((item) => item.id !== id)];
    state.isError = false;
  },
  DeleteUserFailure(state, { message }) {
    state.isError = true;
    state.errorMessage = message;
  },
  UpdateUserSuccess(state, { user }) {
    state.isError = false;
  },
  UpdateUserFailure(state, { message }) {
    state.isError = true;
    state.errorMessage = message;
  },
}