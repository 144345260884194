<template>
  <v-app style="background-color: #f4f4f4">
    <v-navigation-drawer :value="drawer$" color="secondary" dark app>

      <v-layout align-center justify-center pa-8>
        <v-img src="@/assets/logo.png" height="100" contain></v-img>
      </v-layout>

      <v-list nav dense>
        <v-list-item-group color="primary text--white" dark>
          <v-list-item to="/recetas">
            <v-list-item-action>
              <v-icon>mdi-ballot</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Recetas</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/menus">
            <v-list-item-action>
              <v-icon>mdi-silverware-fork-knife</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Menús</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/ingredientes">
            <v-list-item-action>
              <v-icon>mdi-food-apple</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Ingredientes</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/grupo-ingredientes">
            <v-list-item-action>
              <v-icon>mdi-food-variant</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Grupo ingredientes</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/usuarios">
            <v-list-item-action>
              <v-icon>mdi-account</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Usuarios</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>

      <template v-slot:append>
        <div class="pa-6">
          <v-btn block rounded outlined @click="logOut()">Logout</v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <v-content>
      <v-container fluid>

        <router-view />
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
import store from '../store';
import { mapState } from 'vuex';

export default {
  props: {
    source: String,
  },
  computed: {
    drawer$() { 
      return store.getters['user/drawer'];
    }
  },
  methods: {
    logOut() {
      store.dispatch('user/AuthLogOut').then(() => {
        this.$router.push('/');
      }); 
    }
  }
}

</script>

<style>
  .v-list-item--active {
    color: white !important;
  }
</style>
