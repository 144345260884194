<template>
  <v-app>
    <router-view></router-view>
  </v-app>
</template>

<script>
import store from './store';
import axios from 'axios';

export default {
  created: function () {
    axios.interceptors.response.use(undefined, (err) => {
      return new Promise((resolve, reject) => {
        if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
          store.dispatch('user/AuthLogOut').then(() => {
            this.$router.push('/');
          }); 
        }
        throw err;
      });
    });
  }
}
</script>
