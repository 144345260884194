<template>
    <v-container fluid fill-height style="background-color: #2f3238">
        <v-layout align-center justify-center >
          <v-flex text-center grow-shrink-0>
            <v-img src="@/assets/logo.png" height="100" contain></v-img>
            <h3 style="color:white">Acceso</h3>
            <div class="centerWrapper">
              <v-form>
                <v-text-field v-model="email" label="Email" placeholder="Escribe un email válido" required outlined></v-text-field>
                <v-text-field v-model="password" type="password" label="Contraseña" placeholder="Pon tu contraseña" required outlined></v-text-field>
              </v-form>
              <v-btn class="mt-4" color="primary" block @click="login()">Acceder</v-btn>
            </div>

            <v-alert v-if="error$.isError" type="error" class="mt-8">
              {{ error$.errorMessage }}
            </v-alert>
          </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import store from '../store/';

export default {
  name: 'app',
  data () {
    return {
      email: '',
      password: ''
    }
  },
  computed: {
    error$() {
      return store.state.user;
    }
  },
  methods: {
    login() {
      const { email, password } = this;
      store.dispatch('user/AuthRequest', { email, password }).then(() => {
        this.$router.push('/recetas');
      });
    }
  }
}
</script>

<style lang="scss">

h3{
  padding-top: 30px;
}
.centerWrapper{
  margin-top: 30px;
  background-color: white;
  padding: 40px;
  border-radius: 8px;
}

// :root {
//   font-size: 16px;
// }


</style>
