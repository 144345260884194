const recipeStepMaptoDB = {
  title: 'title',
  description: 'main_description',
  recipeId: 'recipe_id',
  order: 'order',
  type: 'type',
  step: 'step',
  stepIngredients: 'step_ingredients'
};

const recipeStepMaptoUI = {
  title: 'title',
  main_description: 'description',
  recipe_id: 'recipeId',
  order: 'order',
  type: 'type',
  step: 'step',
  step_ingredients: 'stepIngredients'
};

const recipeStepMapper = (step, direction) => {
  let recipeStepMapped = {};
  Object.keys(step).forEach((key) => {
    let mapper;
    if (direction === 'toDB') {
      mapper = recipeStepMaptoDB;
      const newKey = mapper[key];

      if (newKey && typeof step[key] !== 'undefined') {
        recipeStepMapped[newKey] = step[key];
      }
    } else {
      mapper = recipeStepMaptoUI;
      const newKey = mapper[key] || key;
      recipeStepMapped[newKey] = step[key];
    }
  });

  return recipeStepMapped;
};

export default recipeStepMapper;
