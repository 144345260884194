import { API, ApiKey } from "../../../config";
import axios from "axios";
import ingredientMapper from "../../../mapper";
import qs from "qs";

const headerAPI = { ApiKey: `${ApiKey}` };

export const actions = {
  async LoadAllIngredients({ commit }) {
    try {
      const { data } = await axios.get(
        `${API}/api/ingredients?&order=name ASC&conditions=%20type%20LIKE%20'%25SIMPLE%25'`,
        { headers: headerAPI }
      );
      commit("LoadAllIngredientsSuccess", { items: data });
    } catch (err) {
      commit("LoadAllIngredientsFailure", {
        message: err.response.data.message,
      });
    }
  },
  async LoadAllGroupIngredients({ commit }) {
    try {
      const { data } = await axios.get(
        `${API}/api/ingredients?&order=name ASC&conditions=%20type%20LIKE%20'%25COMPOUND%25'`,
        { headers: headerAPI }
      );
      commit("LoadAllGroupIngredientsSuccess", { items: data });
    } catch (err) {
      commit("LoadAllGroupIngredientsFailure", {
        message: err.response.data.message,
      });
    }
  },
  async LoadIngredients({ commit }, { offset, limit, conditions, order }) {
    try {
      let queryString = `offset=${offset}&limit=${limit}`;
      if (order) {
        queryString += `&order=${order}`;
      } else {
        queryString += `&order=${"name ASC"}`;
      }
      if (conditions && conditions.length) {
        queryString += `&conditions=${conditions}`;
      }
      const { data, headers } = await axios.get(
        `${API}/api/ingredients?${queryString}`,
        { headers: headerAPI }
      );
      commit("LoadIngredientsSuccess", {
        items: data,
        total: headers["x-total-count"],
      });
    } catch (err) {
      commit("LoadIngredientsFailure", { message: err.response.data.message });
    }
  },
  async LoadAllergens({ commit }) {
    try {
      const { data } = await axios.get(`${API}/api/allergens`, {
        headers: headerAPI,
      });
      commit("LoadAllergensSuccess", { items: data });
    } catch (err) {
      commit("LoadAllergensFailure", { message: err.response.data.message });
    }
  },
  async CreateNewIngredient({ dispatch, commit }, { ingredient }) {
    try {
      const ingredientMapped = ingredientMapper(ingredient, "toDB");
      const { data } = await axios.post(
        `${API}/api/ingredients`,
        qs.stringify({ ...ingredientMapped }),
        { headers: { ...headerAPI } }
      );

      if (ingredient.type === "COMPOUND") {
        for (const simpleIngredient of ingredient.compoundIngredients) {
          await dispatch("CreateNewIngredientCompound", {
            ingredient: { ...simpleIngredient, id: data.id },
          });
        }
      }

      commit("CreateNewIngredientSuccess", { ingredient });
      return true;
    } catch (err) {
      commit("CreateNewIngredientFailure", {
        message: err.response.data.message,
      });
      return false;
    }
  },
  async DeleteIngredient({ commit }, { id }) {
    try {
      await axios.delete(`${API}/api/ingredients/${id}`, {
        headers: { ...headerAPI },
      });
      commit("DeleteIngredientSuccess", { id });
    } catch (err) {
      commit("DeleteIngredientFailure", { message: err.response.data.message });
    }
  },
  async LoadIngredient({ commit }, { id }) {
    try {
      const { data } = await axios.get(`${API}/api/ingredients/${id}`, {
        headers: headerAPI,
      });
      const ingredientMapped = ingredientMapper(data, "toUI");
      commit("LoadIngredientSuccess", { ingredient: ingredientMapped });
    } catch (err) {
      commit("LoadIngredientFailure", { message: err.response.data.message });
    }
  },
  async UpdateIngredient({ commit }, { ingredient }) {
    try {
      const ingredientMapped = ingredientMapper(ingredient, "toDB");
      await axios.put(
        `${API}/api/ingredients/${ingredient.id}`,
        qs.stringify({ ...ingredientMapped }),
        { headers: { ...headerAPI } }
      );
      commit("UpdateIngredientSuccess", { ingredient: ingredientMapped });
      return true;
    } catch (err) {
      commit("UpdateIngredientFailure", { message: err.response.data.message });
      return false;
    }
  },
  async CreateNewIngredientCompound({ commit }, { ingredient }) {
    try {
      await axios.post(
        `${API}/api/ingredients_compound`,
        qs.stringify({
          ingredient_id: ingredient.ingredientId,
          ingredient_compound_id: ingredient.id,
          qty: ingredient.qty,
          units: ingredient.unit,
        }),
        { headers: { ...headerAPI } }
      );
      commit("CreateNewIngredientSuccess", { ingredient });
    } catch (err) {
      commit("CreateNewIngredientFailure", {
        message: err.response.data.message,
      });
    }
  },
  async DeleteIngredientCompound({ commit }, { ingredientId, compoundId }) {
    try {
      await axios.delete(
        `${API}/api/ingredients_compound/${ingredientId}/${compoundId}`,
        { headers: { ...headerAPI } }
      );
      commit("DeleteIngredientCompoundSuccess");
    } catch (err) {
      commit("DeleteIngredientCompoundFailure", {
        message: err.response.data.message,
      });
    }
  },
};
