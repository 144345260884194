export const mutations = {
  AuthRequest(state) {
    state.loading = 'loading';
  },
  AuthRequestSuccess(state, { token }) {
    state.loading = 'success';
    state.token = token;
    state.isError = false;
    state.errorMessage = '';
  },
  AuthRequestFailure(state, { message }) {
    state.loading = 'failure';
    state.isError = true;
    state.errorMessage = message;
  },
  AuthLogOut(state) {
    state.token = '';
  },
  ToggleMenu(state) {
    state.drawer = !state.drawer;
  },
  LoadCurrentUserSuccess(state, { user }) {
    state.user = user;
  }
}
