import { API, ApiKey } from '../../../config';
import axios from 'axios';
import qs from 'qs';

const headerAPI = { ApiKey: `${ApiKey}`};

export const actions = {
  async LoadAllUsers({ commit }) { 
    try {
      const { data } = await axios.get(`${API}/api/users`, { headers: headerAPI });
      commit('LoadAllUsersSuccess', { items: data });
    } catch (err) {
      commit('LoadAllUsersFailure', { message: err.response.data.message });
    }
  },
  async LoadUsers({ commit }, { offset, limit, email, }) {
    try {
      let queryString = `offset=${offset}&limit=${limit}`;
      if (email && email.length) {
        queryString += `&email=${email}`;
      }
      const { data, headers } = await axios.get(`${API}/api/users?${queryString}`, { headers: headerAPI });
      commit('LoadUsersSuccess', { items: data, total: headers['x-total-count'] });
    } catch (err) {
      commit('LoadUsersFailure', { message: err.response.data.message });
    }
  },
  async LoadUser({ commit }, { offset, limit, id }) { 
    try {
      const { data } = await axios.get(`${API}/api/users?offset=${offset}&limit=${limit}&conditions=id=${id}`, { headers: headerAPI });
      commit('LoadUserSuccess', { item: data });
    } catch (err) {
      commit('LoadUserFailure', { message: err.response.data.message });
    }
  },
  async CreateNewUser({ commit }, { user }) {
    try {
      await axios.post(`${API}/api/backoffice/users`, qs.stringify({ ...user }), { headers: headerAPI })
      commit('CreateNewUserSuccess', { user });
      return true;
    } catch (err) {
      commit('CreateNewUserFailure', { message: err.response.data.message });
      return false;
    }
  },
  async UpdateUser({ commit }, { user, id }) {
    try {
      const updatedUser = {...user};
      delete updatedUser.allergen;
      await axios.put(`${API}/api/users/${id}`, qs.stringify({ ...updatedUser }), { headers: headerAPI })
      commit('UpdateUserSuccess', { user });
    } catch (err) {
      commit('UpdateUserFailure', { message: err.response.data.message })
    }
  },
  async DeleteUser({ commit }, { id }) { 
    try {
      await axios.delete(`${API}/api/users/${id}`, { headers: { ...headerAPI }});
      commit('DeleteUserSuccess', { id });
    } catch (err) {
      commit('DeleteUserFailure', { message: err.response.data.message });
    }
  },
}
