const ingredientMaptoDB = {
  allergenId: "allergen_id",
  type: "type",
  includeInShoppingList: "include_in_shopping_list",
  category: "category",
  name: "name",
  description: "description",
  substitute: "substitute",
  vegan: "vegan",
  kcal: "kcal",
  carbs: "carbs",
  fat: "fat",
  protein: "protein",
};

const ingredientMaptoUI = {
  allergen_id: "allergenId",
  type: "type",
  include_in_shopping_list: "includeInShoppingList",
  category: "category",
  name: "name",
  description: "description",
  substitute: "substitute",
  vegan: "vegan",
  kcal: "kcal",
  carbs: "carbs",
  fat: "fat",
  protein: "protein",
};

const ingredientMapper = (ingredient, direction) => {
  let ingredientMapped = {};
  Object.keys(ingredient).forEach((key) => {
    let mapper;
    if (direction === "toDB") {
      mapper = ingredientMaptoDB;
      const newKey = mapper[key];

      // filter out when no matching
      if (newKey && typeof ingredient[key] !== "undefined") {
        ingredientMapped[newKey] = ingredient[key];
      }
    } else {
      mapper = ingredientMaptoUI;
      const newKey = mapper[key] || key;
      ingredientMapped[newKey] = ingredient[key];
    }
  });

  return ingredientMapped;
};

export default ingredientMapper;
