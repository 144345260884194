var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { staticStyle: { "background-color": "#f4f4f4" } },
    [
      _c(
        "v-navigation-drawer",
        {
          attrs: { value: _vm.drawer$, color: "secondary", dark: "", app: "" },
          scopedSlots: _vm._u([
            {
              key: "append",
              fn: function() {
                return [
                  _c(
                    "div",
                    { staticClass: "pa-6" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { block: "", rounded: "", outlined: "" },
                          on: {
                            click: function($event) {
                              return _vm.logOut()
                            }
                          }
                        },
                        [_vm._v("Logout")]
                      )
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c(
            "v-layout",
            { attrs: { "align-center": "", "justify-center": "", "pa-8": "" } },
            [
              _c("v-img", {
                attrs: {
                  src: require("@/assets/logo.png"),
                  height: "100",
                  contain: ""
                }
              })
            ],
            1
          ),
          _c(
            "v-list",
            { attrs: { nav: "", dense: "" } },
            [
              _c(
                "v-list-item-group",
                { attrs: { color: "primary text--white", dark: "" } },
                [
                  _c(
                    "v-list-item",
                    { attrs: { to: "/recetas" } },
                    [
                      _c(
                        "v-list-item-action",
                        [_c("v-icon", [_vm._v("mdi-ballot")])],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [_c("v-list-item-title", [_vm._v("Recetas")])],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    { attrs: { to: "/menus" } },
                    [
                      _c(
                        "v-list-item-action",
                        [_c("v-icon", [_vm._v("mdi-silverware-fork-knife")])],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [_c("v-list-item-title", [_vm._v("Menús")])],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    { attrs: { to: "/ingredientes" } },
                    [
                      _c(
                        "v-list-item-action",
                        [_c("v-icon", [_vm._v("mdi-food-apple")])],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [_c("v-list-item-title", [_vm._v("Ingredientes")])],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    { attrs: { to: "/grupo-ingredientes" } },
                    [
                      _c(
                        "v-list-item-action",
                        [_c("v-icon", [_vm._v("mdi-food-variant")])],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", [
                            _vm._v("Grupo ingredientes")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    { attrs: { to: "/usuarios" } },
                    [
                      _c(
                        "v-list-item-action",
                        [_c("v-icon", [_vm._v("mdi-account")])],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [_c("v-list-item-title", [_vm._v("Usuarios")])],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-content",
        [_c("v-container", { attrs: { fluid: "" } }, [_c("router-view")], 1)],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }