export const CATEGORY_OPTIONS = [
  { value: "aceites", label: "Aceites" },
  { value: "verduras", label: "Verduras" },
  { value: "frutas", label: "Frutas" },
  { value: "cereales", label: "Cereales" },
  { value: "bebidas", label: "Bebidas" },
  { value: "salsas", label: "Salsas" },
  { value: "legumbres", label: "Legumbres" },
  { value: "frutos_secos", label: "Frutos Secos" },
  { value: "semillas", label: "Semillas" },
  { value: "condimentos", label: "Despensa" },
  { value: "hierbas", label: "Hierbas" },
  { value: "especias", label: "Especias" },
  { value: "pescado_huevos", label: "Pescado - Huevos"},
  { value: "otros", label: "Otros" },
];

export const INGREDIENT_TYPE = [
  { value: "SIMPLE", label: "Simple" },
  { value: "COMPOUND", label: "Compuesto" },
];

export const RECIPE_TYPE = [
  { value: "Desayuno", label: "Desayuno" },
  { value: "Comida", label: "Comida" },
  { value: "Merienda", label: "Merienda" },
  { value: "Cena", label: "Cena" },
];

export const UNITS_OF_MEASURE = [
  { value: "u", label: "Unidad/unidades" },
  { value: "g", label: "Gramos (g)" },
  { value: "ml", label: "Mililitros (ml)" },
  { value: "cm", label: "Cm (centímetros)" },
  { value: "cuchara_sopera", label: "c.s. = cuchara sopera" },
  { value: "cuchara_postre", label: "c.p. = cuchara de postre" },
  { value: "hoja", label: "Hoja/Hojas" },
  { value: "manojo", label: "Manojo" },
  { value: "ramillete", label: "Ramillete" },
  { value: "punado", label: "Puñado" },
  { value: "diente", label: "Diente/dientes" },
  { value: "rama", label: "Rama" },
  { value: "taza", label: "Taza" },
  { value: "trozo", label: "Trozo" },
  { value: "cabeza", label: "Cabeza" },
  { value: "rebanada", label: "Rebanada" },
  { value: "vaina", label: "Vaina" },
  { value: "rodaja", label: "Rodaja" },
  { value: "pizca", label: "Pizca" },
  { value: "cantidad_necesaria", label: "Cantidad necesaria" },
  { value: "al_gusto", label: "Al gusto" },
];

export const VARIANT = [
  { value: "dried", label: "seco" },
  { value: "cooked", label: "cocido" },
  { value: "boiled", label: "hervido" },
];

export const USERS_TYPE = [
  { value: "REGULAR", label: "Regular" },
  { value: "ADMIN", label: "Admin" },
  { value: "AUTHOR", label: "Author" },
];

export const INGREDIENT_ORDER = [
  { value: "alphabetical", label: "Orden alfabético" },
  { value: "date", label: "Fecha publicación" },
];

export const RECIPES_FILTER = [
  { value: "is_breakfast", label: "Desayuno" },
  { value: "is_lunch", label: "Comida" },
  { value: "is_snack", label: "Merienda" },
  { value: "is_dinner", label: "Cena" },
  { value: "published", label: "Publicada" },
  { value: "!published", label: "Sin publicar" },
  { value: "vegan", label: "Vegana" },
  { value: "!vegan", label: "Flexible" },
];

export const STEP_TYPE = [
  { value: "remojar", label: "Remojar" },
  { value: "hornear", label: "Hornear" },
  { value: "cocer", label: "Cocer" },
  { value: "saltear", label: "Saltear" },
  { value: "mezclar", label: "Mezclar" },
  { value: "triturar", label: "Triturar" },
  { value: "macerar", label: "Macerar" },
  { value: "preparar", label: "Preparar" },
];

export const TABLE_NUM_ITEMS = 15;
