import { API, ApiKey } from '../../../config';
import axios from 'axios';
import qs from 'qs';

const headerAPI = { ApiKey: `${ApiKey}` };

export const actions = {
  async LoadMenus({ commit }, { offset, limit }) {
    try {
      const { data, headers } = await axios.get(
        `${API}/api/menus/plans?offset=${offset}&limit=${limit}`,
        { headers: headerAPI }
      );
      commit('LoadMenusSuccess', {
        items: data,
      });

      commit('LoadMenuTotalItemsSuccess', {
        totalItems: headers['x-total-count'],
      });
    } catch (err) {
      commit('LoadMenusFailure', { message: err.response.data.message });
    }
  },
  async CreateNewMenu({ commit }, { menu, date }) {
    try {
      if (
        date &&
        menu &&
        (menu.breakfast_id ||
          menu.lunch_id ||
          menu.lunch_starter_id ||
          menu.snack_id ||
          menu.dinner_id ||
          menu.dinner_starter_id)
      ) {
        const menuMapped = { ...menu, date }; //recipeMapper(recipe, 'toDB');
        await axios.post(`${API}/api/menus`, qs.stringify({ ...menuMapped }), {
          headers: { ...headerAPI },
        });
        commit("CreateNewMenuSuccess", { menu });
        return true;
      } else {
        commit("CreateNewMenuFailure", { message: "menu incorrecto" });
        return false;
      }
    } catch (err) {
      commit("CreateNewMenuFailure", { message: "menu incorrecto" });
      return false;
    }
  },
  async DeleteMenu({ commit }, { id }) {
    try {
      await axios.delete(`${API}/api/menus/plans/${id}`, {
        headers: { ...headerAPI },
      });
      commit("DeleteMenuSuccess", { id });
    } catch (err) {
      commit("DeleteMenuFailure", { message: err.response.data.message });
    }
  },
  async UpdateMenu({ commit }, { menus, dates }) {
    try {
      for (const [index, menu] of menus.entries()) {
        if (
          dates[index] &&
          menu &&
          (menu.breakfast_id ||
            menu.lunch_id ||
            menu.lunch_starter_id ||
            menu.snack_id ||
            menu.dinner_id ||
            menu.dinner_starter_id)
        ) {
          const menuMapped = {
            breakfast_id: menu.breakfast_id || "",
            lunch_id: menu.lunch_id || "",
            snack_id: menu.snack_id || "",
            dinner_id: menu.dinner_id || "",
            dinner_starter_id: menu.dinner_starter_id || "",
            lunch_starter_id: menu.lunch_starter_id || "",
            vegan: menu.vegan || false,
            date: dates[index],
          };
          if (!menu.id || menu.id === 0) {
            await axios.post(
              `${API}/api/menus/`,
              qs.stringify({ ...menuMapped }),
              { headers: { ...headerAPI } }
            );
          } else {
            await axios.put(
              `${API}/api/menus/${menu.id}`,
              qs.stringify({ ...menuMapped }),
              { headers: { ...headerAPI } }
            );
          }
        } else {
          if (menu.id) {
            const menuMapped = {
              breakfast_id: null,
              lunch_id: null,
              snack_id: null,
              dinner_id: null,
              dinner_starter_id: null,
              lunch_starter_id: null,
              vegan: menu.vegan || false,
              date: dates[index],
            };
            await axios.put(
              `${API}/api/menus/${menu.id}`,
              qs.stringify({ ...menuMapped }),
              { headers: { ...headerAPI } }
            );
          } else {
            commit("UpdateMenuFailure", { message: "menu incorrecto" });
          }
        }
      }
      commit("UpdateMenuSuccess", { menus, dates });
    } catch (err) {
      commit("UpdateMenuFailure", { message: err.response.data.message });
    }
  },
};
