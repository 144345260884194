import store from '../store/index';
import { TABLE_NUM_ITEMS } from '../constants';

const recipesGuard = (to, from, next) => {
  store.dispatch('recipes/LoadRecipes', { offset: 0, limit: TABLE_NUM_ITEMS, order: `created DESC` }).then(() => next());
  store.dispatch('ingredients/LoadAllIngredients').then(() => next());
  store.dispatch('ingredients/LoadAllGroupIngredients').then(() => next());
  store.dispatch('users/LoadAllUsers').then(() => next());
}

export default recipesGuard;
