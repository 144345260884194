import store from '../store/index';

export const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters['user/isAuthenticated']) {
    next()
    return
  }
  next('/ingredientes')
}

export const ifAuthenticated = (to, from, next) => {
  if (store.getters['user/isAuthenticated']) {
    store.dispatch('user/LoadCurrentUser');
    next()
    return
  }
  next('/')
};
