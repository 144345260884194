export const mutations = {
  LoadingRecipes(state) {
    state.loading = true;
  },
  LoadRecipesSuccess(state, { items, total }) { 
    state.items = [...items];
    state.totalItems = total;
    state.loading = false;
    state.isError = false;
  },
  LoadRecipesFailure(state, { message }) {
    state.loading = false;
    state.isError = true;
    state.errorMessage = message;
  },
  CreateNewRecipeSuccess(state) { 
    state.isError = false;
  },
  CreateNewRecipeFailure(state, { message }) {
    state.isError = true;
    state.errorMessage = message;
  },
  UpdateIngredientSuccess(state) {
    state.isError = false;
  },
  UpdateIngredientFailure(state, { message }) {
    state.isError = true;
    state.errorMessage = message;
  },
  UpdateRecipeSuccess(state, { id }) {
    state.isError = false;
  },
  UpdateRecipeFailure(state, { message }) {
    state.isError = true;
    state.errorMessage = message;
  },
  DeleteRecipeSuccess(state, { id }) {
    state.items = [...state.items.filter((item) => item.id !== id)];
    state.isError = false;
  },
  DeleteRecipeFailure(state, { message }) {
    state.isError = true;
    state.errorMessage = message;
  },
  DeleteStepSuccess(state) {
    state.isError = false;
  },
  UpdateStepSuccess(state) {
    state.isError = false;
  },
  UploadImageSuccess(state) {
    state.isError = false;
  },
  UploadImageFailure(state, { message }) {
    state.isError = true;
    state.errorMessage = message;
  },
  DeleteRecipeIngredient(state) {
    state.isError = false;
  },
  UpdateRecipeIngredientSuccess(state) {
    state.isError = false;
  },
  UpdateRecipeIngredientFailure(state, { message }) {
    state.isError = true;
    state.errorMessage = message;
  }
};