import Vue from 'vue'
import Router from 'vue-router'

import Login from './pages/Login'
import Dashboard from './pages/Dashboard'
import ingredientsGuard from './guards/ingredients';
import groupIngredientsGuard from './guards/groupIngredients';
import createIngredientGuard from './guards/createIngredientGuard';
import editIngredientsGuard from './guards/editIngredient';
import recipesGuard from './guards/recipes';
import menusGuard from './guards/menus';
import usersGuard from './guards/users';
import createUserGuard from './guards/createUserGuard';
import { ifNotAuthenticated, ifAuthenticated } from './guards/auth';

Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Login',
      component: Login,
      beforeEnter: ifNotAuthenticated
    },
    {
      path: '/admin',
      name: 'Dashboard',
      component: Dashboard,
      beforeEnter: ifAuthenticated,
      children: [
        {
          path: '/ingredientes',
          component: () => import(/* webpackChunkName: "ingredients" */ './pages/ingredientes/listado.vue'),
          beforeEnter: ingredientsGuard
        },
        {
          path: '/ingredientes/nuevo',
          component: () => import(/* webpackChunkName: "ingredients" */ './pages/ingredientes/nuevo.vue'),
          beforeEnter: createIngredientGuard
        },
        {
          path: '/ingredientes/editar/:id',
          component: () => import(/* webpackChunkName: "ingredients" */ './pages/ingredientes/editar.vue'),
          beforeEnter: editIngredientsGuard
        },
        {
          path: '/grupo-ingredientes',
          component: () => import(/* webpackChunkName: "groupIngredients" */ './pages/grupo-ingredientes/listado.vue'),
          beforeEnter: groupIngredientsGuard
        },
        {
          path: '/grupo-ingredientes/nuevo',
          component: () => import(/* webpackChunkName: "groupIngredients" */ './pages/grupo-ingredientes/nuevo.vue'),
          beforeEnter: createIngredientGuard
        },
        {
          path: '/grupo-ingredientes/editar/:id',
          component: () => import(/* webpackChunkName: "groupIngredients" */ './pages/grupo-ingredientes/editar.vue'),
          beforeEnter: editIngredientsGuard
        },
        {
          path: '/recetas',
          component: () => import(/* webpackChunkName: "recipes" */ './pages/recetas/listado.vue'),
          beforeEnter: recipesGuard
        },
        {
          path: '/recetas/nueva',
          component: () => import(/* webpackChunkName: "recipes" */ './pages/recetas/nueva.vue'),
          beforeEnter: createIngredientGuard
        },
        {
          path: '/recetas/editar/:id',
          component: () => import(/* webpackChunkName: "recipes" */ './pages/recetas/editar.vue'),
          beforeEnter: createIngredientGuard
        },
        {
          path: '/menus',
          component: () => import(/* webpackChunkName: "menus" */ './pages/menus/listado.vue'),
          beforeEnter: menusGuard
        },
        {
          path: '/menus/nuevo',
          component: () => import(/* webpackChunkName: "menus" */ './pages/menus/nuevo.vue'),
          beforeEnter: menusGuard
        },
        {
          path: '/menus/editar/:id',
          component: () => import(/* webpackChunkName: "menus" */ './pages/menus/editar.vue'),
          beforeEnter: menusGuard
        },
        {
          path: '/usuarios',
          component: () => import(/* webpackChunkName: "users" */ './pages/usuarios/listado.vue'),
          beforeEnter: usersGuard
        },
        {
          path: '/usuarios/nuevo',
          component: () => import(/* webpackChunkName: "users" */ './pages/usuarios/nuevo.vue'),
          beforeEnter: createUserGuard
        },
        {
          path: '/usuarios/editar/:id',
          component: () => import(/* webpackChunkName: "users" */ './pages/usuarios/editar.vue')
        },
      ]
    }
  ]
})