var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      staticStyle: { "background-color": "#2f3238" },
      attrs: { fluid: "", "fill-height": "" }
    },
    [
      _c(
        "v-layout",
        { attrs: { "align-center": "", "justify-center": "" } },
        [
          _c(
            "v-flex",
            { attrs: { "text-center": "", "grow-shrink-0": "" } },
            [
              _c("v-img", {
                attrs: {
                  src: require("@/assets/logo.png"),
                  height: "100",
                  contain: ""
                }
              }),
              _c("h3", { staticStyle: { color: "white" } }, [_vm._v("Acceso")]),
              _c(
                "div",
                { staticClass: "centerWrapper" },
                [
                  _c(
                    "v-form",
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Email",
                          placeholder: "Escribe un email válido",
                          required: "",
                          outlined: ""
                        },
                        model: {
                          value: _vm.email,
                          callback: function($$v) {
                            _vm.email = $$v
                          },
                          expression: "email"
                        }
                      }),
                      _c("v-text-field", {
                        attrs: {
                          type: "password",
                          label: "Contraseña",
                          placeholder: "Pon tu contraseña",
                          required: "",
                          outlined: ""
                        },
                        model: {
                          value: _vm.password,
                          callback: function($$v) {
                            _vm.password = $$v
                          },
                          expression: "password"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mt-4",
                      attrs: { color: "primary", block: "" },
                      on: {
                        click: function($event) {
                          return _vm.login()
                        }
                      }
                    },
                    [_vm._v("Acceder")]
                  )
                ],
                1
              ),
              _vm.error$.isError
                ? _c(
                    "v-alert",
                    { staticClass: "mt-8", attrs: { type: "error" } },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.error$.errorMessage) +
                          "\n        "
                      )
                    ]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }