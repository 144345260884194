import { API, ApiKey } from "../../../config";
import axios from "axios";
import recipeMapper from "../../../mapper/recipe";
import stepMapper from "../../../mapper/recipe_step";
import recipeIngredientMapper from "../../../mapper/recipe_ingredient";
import qs from "qs";

const headerAPI = { ApiKey: `${ApiKey}` };
const headerImage = {
  Accept: "application/json",
  "Content-Type": "multipart/form-data",
};

export const actions = {
  async LoadRecipes({ commit }, { offset, limit, conditions, order }) {
    try {
      let queryString = "";
      if (offset) {
        queryString += `offset=${offset}`;
      }
      if (order) {
        queryString += `&order=${order}`;
      }
      if (limit) {
        queryString += `&limit=${limit}`;
      }
      if (conditions && conditions.length) {
        queryString += `&conditions=${conditions}`;
      }
      const { data, headers } = await axios.get(
        `${API}/api/recipes?${queryString}`,
        { headers: headerAPI }
      );
      commit("LoadRecipesSuccess", {
        items: data,
        total: headers["x-total-count"],
      });
    } catch (err) {
      commit("LoadRecipesFailure", { message: err.response.data.message });
    }
  },
  async CreateNewRecipe({ commit }, { recipe, steps, ingredients }) {
    try {
      const recipeMapped = recipeMapper(recipe, "toDB");

      const { data } = await axios.post(
        `${API}/api/recipes`,
        qs.stringify({ ...recipeMapped }),
        { headers: { ...headerAPI } }
      );

      // map ingredient - recipe_ingredient
      const recipeAndrecipeIngredientMap = {};

      // relational recipe ingredients

      for (let i = 0; i < ingredients.length; ++i) {
        const ingredientsMapped = recipeIngredientMapper(
          { ...ingredients[i], recipeId: parseInt(data.id, 10), order: i },
          "toDB"
        );

        if (!ingredientsMapped.title) {
          delete ingredientsMapped.title;
        }

        if (!ingredientsMapped.alternative_id) {
          delete ingredientsMapped.alternative_id;
        }

        if (!ingredientsMapped.qty) {
          ingredientsMapped.qty = 1;
        }

        if (!ingredientsMapped.units_of_measure) {
          delete ingredientsMapped.units_of_measure;
        }

        if (!ingredientsMapped.variant) {
          delete ingredientsMapped.variant;
        }

        delete ingredientsMapped.id;

        const parentRecipeIngredient = await axios.post(
          `${API}/api/recipe_ingredients`,
          qs.stringify(ingredientsMapped),
          { headers: { ...headerAPI } }
        );

        // set relation - watch out ingredient is already mapped at this point
        recipeAndrecipeIngredientMap[ingredients[i].ingredientId] =
          parentRecipeIngredient.data.id;
        if (
          ingredients[i].alternative &&
          ingredients[i].alternative.ingredientId
        ) {
          ingredients[i].alternative.alternativeId =
            parentRecipeIngredient.data.id;
          const alternativeIngredientsMapped = recipeIngredientMapper(
            {
              ...ingredients[i].alternative,
              recipeId: parseInt(data.id, 10),
              order: i,
            },
            "toDB"
          );
          await axios.post(
            `${API}/api/recipe_ingredients`,
            qs.stringify({ ...alternativeIngredientsMapped }),
            { headers: { ...headerAPI } }
          );
        }
      }

      // relational recipe steps
      for (const [index, step] of steps.entries()) {
        // get ingredient steps
        step.stepIngredients = [];
        for (let i = 0; i < step.alternative.length; i++) {
          if (recipeAndrecipeIngredientMap[step.alternative[i].ingredientId]) {
            step.stepIngredients.push({
              recipes_ingredients_id:
                recipeAndrecipeIngredientMap[step.alternative[i].ingredientId],
              alternative_description: step.alternative[i].description,
            });
          }
        }

        const stepMapped = stepMapper(
          { ...step, recipeId: parseInt(data.id, 10), order: index },
          "toDB"
        );
        await axios.post(
          `${API}/api/recipe_steps`,
          { ...stepMapped },
          { headers: { ...headerAPI } }
        );
      }

      commit("CreateNewRecipeSuccess", { recipe });
      return true;
    } catch (err) {
      commit("CreateNewRecipeFailure", { message: err.response.data.message });
      return false;
    }
  },
  async UpdateRecipe({ commit }, { recipe }) {
    try {
      const recipeMapped = recipeMapper(recipe, "toDB");
      await axios.put(
        `${API}/api/recipes/${recipe.id}`,
        qs.stringify({ ...recipeMapped }),
        { headers: { ...headerAPI } }
      );

      commit("UpdateRecipeSuccess", { recipe });
    } catch (err) {
      commit("UpdateRecipeFailure", { message: err.response.data.message });
    }
  },
  async DeleteRecipe({ commit }, { id }) {
    try {
      await axios.delete(`${API}/api/recipes/${id}`, {
        headers: { ...headerAPI },
      });
      commit("DeleteRecipeSuccess", { id });
    } catch (err) {
      commit("DeleteRecipeFailure", { message: err.response.data.message });
    }
  },
  async UploadImage({ commit }, { image }) {
    try {
      const imgBody = new FormData();
      imgBody.append("file", image);

      const { data } = await axios.post(`${API}/api/files/recipe`, imgBody, {
        headers: { ...headerAPI, ...headerImage },
      });
      commit("UploadImageSuccess");
      return data.ObjectURL;
    } catch (err) {
      commit("UploadImageFailure", { message: err.response.data.message });
    }
  },
  async CreateNewStep({ commit }, { recipeId, order, step }) {
    try {
      // get ingredient steps
      step.stepIngredients = [];
      step.stepIngredients = [];
      for (let i = 0; i < step.alternative.length; i++) {
        if (step.alternative[i].id) {
          step.stepIngredients.push({
            recipes_ingredients_id: step.alternative[i].id,
            alternative_description: step.alternative[i].description,
          });
        }
      }

      const stepMapped = stepMapper(
        { ...step, recipeId: parseInt(recipeId, 10), order },
        "toDB"
      );
      const newStep = await axios.post(
        `${API}/api/recipe_steps`,
        { ...stepMapped },
        { headers: { ...headerAPI } }
      );

      commit("CreateNewRecipeSuccess");
      return newStep.data.id;
    } catch (err) {
      commit("CreateNewRecipeFailure", { message: err.response.data.message });
    }
  },
  async DeleteStep({ commit }, { stepId }) {
    try {
      await axios.delete(`${API}/api/recipe_steps/${stepId}`, {
        headers: { ...headerAPI },
      });
      commit("DeleteStepSuccess");
    } catch (err) {
      commit("CreateNewRecipeFailure", { message: err.response.data.message });
    }
  },
  async UpdateStep({ commit }, { recipeId, step }) {
    try {
      // get ingredient steps
      step.stepIngredients = [];
      for (let i = 0; i < step.alternative.length; i++) {
        if (step.alternative[i].id) {
          step.stepIngredients.push({
            recipes_ingredients_id: step.alternative[i].id,
            alternative_description: step.alternative[i].description,
          });
        }
      }

      const stepMapped = stepMapper(
        { ...step, recipeId: parseInt(recipeId, 10) },
        "toDB"
      );
      await axios.put(
        `${API}/api/recipe_steps/${step.id}`,
        { ...stepMapped },
        { headers: { ...headerAPI } }
      );
      commit("UpdateStepSuccess");
    } catch (err) {
      commit("CreateNewRecipeFailure", { message: err.response.data.message });
    }
  },
  async UpdateIngredient({ commit }, { recipeId, ingredient }) {
    try {
      const ingredientsMapped = recipeIngredientMapper(
        {
          ...ingredient,
          recipeId: parseInt(recipeId, 10),
          order: ingredient.order,
        },
        "toDB"
      );
      if (ingredientsMapped.alternative_id === null) {
        delete ingredientsMapped.alternative_id;
      }
      await axios.put(
        `${API}/api/recipe_ingredients/${ingredient.recipes_ingredients_id}`,
        qs.stringify({ ...ingredientsMapped }),
        { headers: { ...headerAPI } }
      );

      if (ingredient.alternative && ingredient.alternative.ingredientId) {
        const alternativeIngredientsMapped = recipeIngredientMapper(
          {
            ...ingredient.alternative,
            recipeId: parseInt(ingredient.recipeId, 10),
            order: ingredient.order,
          },
          "toDB"
        );
        await axios.put(
          `${API}/api/recipe_ingredients/${
            ingredient.alternative.recipes_ingredients_id
          }`,
          qs.stringify({ ...alternativeIngredientsMapped }),
          { headers: { ...headerAPI } }
        );
      }

      commit("UpdateIngredientSuccess");
    } catch (err) {
      commit("UpdateIngredientFailure", { message: err.response.data.message });
    }
  },
  async UpdateStepOrder({ commit }, { recipeId, step }) {
    try {
      step.stepIngredients = [];
      for (let i = 0; i < step.alternative.length; i++) {
        step.stepIngredients.push({
          recipes_ingredients_id: step.alternative[i].recipes_ingredients_id,
          alternative_description: step.alternative[i].alternative_description,
        });
      }
      const stepMapped = stepMapper(
        { ...step, recipeId: parseInt(recipeId, 10) },
        "toDB"
      );
      await axios.put(
        `${API}/api/recipe_steps/${step.id}`,
        { ...stepMapped },
        { headers: { ...headerAPI } }
      );
      commit("UpdateStepSuccess");
    } catch (err) {
      commit("CreateNewRecipeFailure", { message: err.response.data.message });
    }
  },
  async CreateNewRecipeIngredient({ commit }, { recipeId, order, ingredient }) {
    try {
      const ingredientsMapped = recipeIngredientMapper(
        { ...ingredient, recipeId: parseInt(recipeId, 10), order },
        "toDB"
      );
      if (ingredientsMapped.alternative_id === null) {
        delete ingredientsMapped.alternative_id;
      }
      const parentRecipeIngredient = await axios.post(
        `${API}/api/recipe_ingredients`,
        qs.stringify({ ...ingredientsMapped }),
        { headers: { ...headerAPI } }
      );

      if (ingredient.alternative && ingredient.alternative.ingredientId) {
        ingredient.alternative.alternativeId = parentRecipeIngredient.data.id;
        const alternativeIngredientsMapped = recipeIngredientMapper(
          {
            ...ingredient.alternative,
            recipeId: parseInt(recipeId, 10),
            order,
          },
          "toDB"
        );
        await axios.post(
          `${API}/api/recipe_ingredients`,
          qs.stringify({ ...alternativeIngredientsMapped }),
          { headers: { ...headerAPI } }
        );
      }

      commit("CreateNewRecipeSuccess");
      return parentRecipeIngredient.data.id;
    } catch (err) {
      commit("CreateNewRecipeFailure", { message: err.response.data.message });
    }
  },
  async DeleteRecipeIngredient({ commit }, { recipeIngredientId }) {
    try {
      await axios.delete(
        `${API}/api/recipe_ingredients/${recipeIngredientId}`,
        { headers: { ...headerAPI } }
      );
      commit("DeleteRecipeIngredient");
    } catch (err) {
      commit("CreateNewRecipeFailure", { message: err.response.data.message });
    }
  },
  async UpdateRecipeIngredient({ commit }, { recipeId, ingredient }) {
    try {
      const ingredientsMapped = recipeIngredientMapper(
        { ...ingredient, recipeId: parseInt(recipeId, 10) },
        "toDB"
      );
      if (!ingredientsMapped.alternative_id) {
        delete ingredientsMapped.alternative_id;
      }
      await axios.put(
        `${API}/api/recipe_ingredients/${ingredient.recipes_ingredients_id}`,
        qs.stringify({ ...ingredientsMapped }),
        { headers: { ...headerAPI } }
      );
      commit("UpdateRecipeIngredientSuccess");
    } catch (err) {
      commit("UpdateRecipeIngredientFailure", {
        message: err.response.data.message,
      });
    }
  },
};
