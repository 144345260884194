export const getters = { 
  items: state => state.items,
  allergens: state => state.allergens,
  ingredient: state => state.selectedIngredient,
  simpleIngredients: state => state.allItems.filter(ingredient => ingredient.type === 'SIMPLE'),
  totalItems: state => state.totalItems,
  allItems: state => state.allItems,
  groupItems: state => state.groupItems,
  error: state => ({ isError: state.isError, message: state.errorMessage })
};
