export const state = {
  items: [],
  item: '',
  loading: false,
  totalItems: 0,
  selectedUser: null,
  // TODO: adjust to normal pagination items
  allItems: [],
  isError: false,
  errorMessage: null
};
