const recipeMaptoDB = {
  title: "title",
  preparationTime: "preparation_time",
  published: "published",
  authorId: "author_id",
  creation: "creation",
  imageUrl: "image",
  simplification: "simplification",
  vegan: "vegan",
};

const recipeMaptoUI = {
  title: "title",
  preparation_time: "preparationTime",
  published: "published",
  author_id: "authorId",
  image: "imageUrl",
  simplification: "simplification",
  vegan: "vegan",
};

const recipeMapper = (recipe, direction) => {
  let recipeMapped = {};
  Object.keys(recipe).forEach((key) => {
    let mapper;
    if (direction === "toDB") {
      mapper = recipeMaptoDB;
    } else {
      mapper = recipeMaptoUI;
    }

    const newKey = mapper[key] || key;
    recipeMapped[newKey] = recipe[key];

    // types
    if (direction === "toDB") {
      recipeMapped.is_breakfast = recipe.types.includes("Desayuno");
      recipeMapped.is_lunch = recipe.types.includes("Comida");
      recipeMapped.is_snack = recipe.types.includes("Merienda");
      recipeMapped.is_dinner = recipe.types.includes("Cena");
      delete recipeMapped.types;
      delete recipeMapped.ingredients;
      delete recipeMapped.steps;
    } else {
      recipeMapped.types = [];
      if (recipeMapped.is_breakfast) recipeMapped.types.push("Desayuno");
      if (recipeMapped.is_lunch) recipeMapped.types.push("Comida");
      if (recipeMapped.is_snack) recipeMapped.types.push("Merienda");
      if (recipeMapped.is_dinner) recipeMapped.types.push("Cena");
    }
  });

  return recipeMapped;
};

export default recipeMapper;
